<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
    </v-skeleton-loader>
  </div>
</template>

<script>
import { GET_QUERY, API_CUSTOMERS } from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";

// import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  data: () => ({
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835"
  }),
  validations() {
    return true;
  },
  watch: {},
  async mounted() {},
  async created() {
    this.loading = true;

    this.isLoading = true;
    await this.$store
      .dispatch(GET_QUERY, {
        listName: API_CUSTOMERS
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 204) {
          let items = response.data.$values;
          this.$router.push({
            name: "CustomerViewRequests",
            params: { customerId: items[0].aspireId }
          });
        } else {
          this.$snackbar.showMessage({
            content: response.data || "Something went wrong!",
            color: "red"
          });
        }
        this.loading = false;
        this.isLoading = false;
      });
    // eslint-disable-next-line no-undef
    // await redirectToDefaultCustomerView();
  },
  methods: {
    async redirectToDefaultCustomerView() {
      this.loading = true;

      this.isLoading = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let items = response.data.$values;
            this.$router.push({
              name: "CustomerView",
              params: { customerId: items[0].aspireId }
            });
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.loading = false;
          this.isLoading = false;
        });
    },

    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers" },
        { title: this.communityInfo.name }
      ]);
    },

    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
.page-specific-background {
  background-image: url("/media/assets/community-info-bg.jpg");
}
</style>
